import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { colors, s, sSection, alpha } from '../../style'
import alt from '../../content/alt'
import { useLangContext } from '../../context/lang.context'
import Label from '../../components/Label'
import Button from '../../components/Button'

const content = {
  windsurfing: {
    firstname: 'Aleksandra',
    lastname: 'Wasiewicz',
    title: {
      pl: 'Windsurfing',
      en: 'Windsurfing',
    },
  },
  bulldogs: {
    firstname: 'Omida',
    lastname: 'Bulldogs',
    title: {
      pl: 'Koszykówka',
      en: 'Basketball',
    },
  },
  readmore: {
    pl: 'Czytaj więcej!',
    en: 'Read more!',
  },
}

const label = {
  text: {
    pl: 'Wspieramy',
    en: 'We support',
  },
  color: colors.brandDarker,
}

const BannerSupporting = () => {
  const { lang } = useLangContext()

  const { windsurfing, bulldogs } = useStaticQuery(graphql`
    {
      windsurfing: file(
        absolutePath: { regex: "/images/intros/aleksandra-wasiewicz.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
      bulldogs: file(
        absolutePath: {
          regex: "/images/supporting/bulldogs/omida-bulldogs.jpg/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
    }
  `)

  return (
    <section css={[sSection, sRelative]}>
      <div css={sBanner}>
        <div css={[sRelative, { height: 'max-content' }]}>
          <GatsbyImage
            image={getImage(windsurfing.childImageSharp)}
            alt={
              content.windsurfing.firstname +
              ' ' +
              content.windsurfing.lastname +
              alt
            }
            css={sImageCeo}
          />
          <div css={[sDimmer, sImageCeo]} />
          <div css={sText}>
            <span>{content.windsurfing.title[lang]}</span>
            <p>{content.windsurfing.firstname}</p>
            <p css={{ marginBottom: '2rem' }}>{content.windsurfing.lastname}</p>
            <Button link='/company/supporting/aleksandra-wasiewicz/' small>
              {content.readmore[lang]}
            </Button>
          </div>
        </div>
        <div css={[sRelative, { height: 'max-content' }]}>
          <GatsbyImage
            image={getImage(bulldogs.childImageSharp)}
            alt={
              content.bulldogs.firstname + ' ' + content.bulldogs.lastname + alt
            }
            css={sImageVice}
            imgStyle={{ objectPosition: 'bottom' }}
          />
          <div css={[sDimmer, sImageVice]} />
          <div css={[sText, { [s.md]: { alignItems: 'flex-end' } }]}>
            <span>{content.bulldogs.title[lang]}</span>
            <p>{content.bulldogs.firstname}</p>
            <p css={{ marginBottom: '2rem' }}>{content.bulldogs.lastname}</p>
            <Button link='/company/supporting/omida-bulldogs/' small>
              {content.readmore[lang]}
            </Button>
          </div>
        </div>
      </div>
      <div css={sLabel}>
        <Label text={label.text[lang]} color={label.color} />
      </div>
    </section>
  )
}

const sDimmer = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `linear-gradient(to top, ${
    colors.brandBack
  }, ${colors.brandDark.concat(alpha[90])}, ${colors.brandDark.concat(
    alpha[0]
  )} 66%)`,
  borderRadius: 'inherit',
  mixBlendMode: 'multiply',
}

const sText = {
  top: 0,
  left: 0,
  position: 'absolute',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  width: '100%',
  padding: '4rem',
  [s.sm_down]: {
    padding: '2rem',
  },

  span: {
    textTransform: 'uppercase',
    color: colors.white,
    marginBottom: '1rem',
    fontWeight: 700,
    fontSize: '1rem',
    [s.sm_down]: {
      fontSize: '0.75rem',
      marginBottom: '0.5rem',
    },
  },

  p: {
    fontSize: '4rem',
    letterSpacing: '-0.05em',
    lineHeight: '1em',
    color: colors.white,
    fontWeight: 700,
    [s.sm_down]: {
      fontSize: '3rem',
    },
  },
}

const sRelative = {
  position: 'relative',
  top: 0,
  left: 0,
}

const sBanner = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 1,
  borderRadius: '4rem',
  height: 'max-content',
  [s.sm_down]: {
    borderRadius: '1.5rem',
    gridTemplateColumns: '1fr',
  },
}

const sImageCeo = {
  borderRadius: '4rem 0 0 4rem',
  [s.sm_down]: {
    borderRadius: '2rem 2rem 0 0',
  },
}

const sImageVice = {
  ...sImageCeo,
  [s.sm_down]: {
    borderRadius: '0 0 2rem 2rem',
  },
  [s.md]: {
    borderRadius: '0 4rem 4rem 0',
  },
}

const sLabel = {
  position: 'absolute',
  top: 4,
  left: '50%',
  transform: 'translate(-50%, -100%)',
  zIndex: 0,
}

export default BannerSupporting
