import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import {
  seo,
  intro,
  main,
  features,
  join,
  video,
  supporting,
} from '../../content/kariera'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import ImageSection from '../../components/ImageSection'
import Features from '../../components/Features'
// import JobSection from '../../sections/index/JobSection'
// import { main as jobsMain } from '../../content/praca'
import Collapses from '../../components/Collapses'
import BannerRecruitment from '../../sections/banners/BannerRecruitment'
import Video from '../../components/Video'
import BannerSupporting from '../../sections/banners/BannerSupporting'

const Kariera = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/kariera.png/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      main1: file(absolutePath: { regex: "/images/company/career.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      gptw23: file(absolutePath: { regex: "/images/company/gptw23en_large.png/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Dowiedz się dlaczego my',
      en: 'Learn why us',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        position='top'
      />
      <Main
        h={1}
        title={main.title[lang]}
        subtitle={main.subtitle[lang]}
        body={main.body[lang]}
      >
          {main.gptw23?.map(({ title, desc }, id) => (
              <ImageSection
                  h={2}
                  key={id}
                  title={title[lang]}
                  desc={desc[lang]}
                  image={query.gptw23.publicURL}
                  imgStyle={{maxWidth: '200px', margin: '0 auto'}}
                  containerStyle={{display: 'flex', justifyContent: 'center'}}
              />
          ))}
      </Main>
        <Main h={2} title={features.title[lang]} desc={features.desc[lang]}>
            <Features data={features.features} gridRepeat={3} />
        </Main>

      <Main h={2} title={video.title[lang]} body={video.desc[lang]}>
        <div style={{ paddingTop: '4rem' }} />
        <Video video={video.video} videos={video.videos} />

          {/* {main.sections?.map(({ title, desc }, id) => (
          <ImageSection
            h={2}
            key={id}
            title={title[lang]}
            desc={desc[lang]}
            image={query[`main${id + 1}`].childImageSharp}
          />
        ))} */}
      </Main>
      <Main h={2} title={supporting.title[lang]}>
        <Video video={supporting.video} videos={supporting.videos} />
      </Main>
      <BannerSupporting />

      <Main
        h={2}
        s={1}
        title={join.title[lang]}
        desc={join.desc[lang]}
        subtitle={join.subtitle[lang]}
      >
        <Collapses data={join.stages} openAll />
        <BannerRecruitment />
      </Main>
    </Layout>
  )
}

export default Kariera
